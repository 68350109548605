<template>
  <settings-provider>
    <router-view />
  </settings-provider>
</template>

<script>
import SettingsProvider from "./providers/SettingsProvider";

const hideIframeLoadingIndicator = () =>
  window?.parent.postMessage("HIDE_IFRAME_LOADING_INDICATOR", "*");

export default {
  created: () => {
    hideIframeLoadingIndicator();
  },
  components: {
    SettingsProvider,
  },
};
</script>

<style src="@/assets/css/main.css"></style>
<style src="@/assets/css/animations.css"></style>

<style lang="scss">
.animate__delay-25s {
  -webkit-animation-delay: .25s;
  animation-delay: .25s;
}

.animate__delay-50s {
  -webkit-animation-delay: .50s;
  animation-delay: .50s;
}

$animationDuration: 0.5s;
@import "vue2-animate/src/sass/vue2-animate.scss";
</style>
