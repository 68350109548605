<template>
  <form
    @submit.prevent="handleFormSubmit()"
    class="animate__animated animate__fadeIn animate__delay-50s"
  >
    <wyse-input-group
      type="text"
      :error="errors.firstName || errors.lastName"
      label="Name"
      placeholderOne="First"
      placeholderTwo="Last"
      :value-one="formData.firstName"
      :value-two="formData.lastName"
      @valueOne="formData.firstName = $event"
      @valueTwo="formData.lastName = $event"
      required
    />
    <wyse-input
      type="email"
      :error="errors.email"
      label="Email"
      v-model="formData.email"
      required
    />
    <wyse-input
      type="tel"
      :error="errors.phone"
      label="Phone"
      v-model="formData.phone"
      required
    />

    <wyse-input
      type="text"
      :error="errors.desiredBedroom"
      label="Desired Bedrooms"
      v-model="formData.desiredBedroom"
      :options="bedroomOptions"
    />

    <wyse-input
      type="text"
      :error="errors.desiredMoveInDate"
      label="Desired Move-in"
      v-model="formData.desiredMoveInDate"
      :options="[
        { value: 'Immediately', text: 'Immediately' },
        { value: '1 Month', text: '1 Month' },
        { value: '2 Months', text: '2 Months' },
        { value: '3 Months', text: '3 Months' },
        { value: 'More Than 3 Months', text: 'More Than 3 Months' },
      ]"
    />

    <tips-text v-if="!showMessage">
      Would you like to
      <a @click="showMessage = true">
        add a message
      </a>
      with your tour request?
    </tips-text>
    <transition name="fade">
      <wyse-textarea
        v-if="showMessage"
        label="Message"
        :error="errors.message"
        v-model="formData.message"
      />
    </transition>

    <wyse-base-button type="submit" :disabled="loading" clas>
      <spinner v-if="loading" :size="20" :borderWidth="3" />
      <template v-else>Contact</template>
    </wyse-base-button>
  </form>
</template>

<script>
import WyseInput from "../components/form/WyseInput";
//import WyseSelect from "../components/form/WyseSelect";
import WyseInputGroup from "../components/form/WyseInputGroup";
import WyseTextarea from "../components/form/WyseTextarea";
import router from "../router";
import Spinner from "./Spinner.vue";
import TipsText from "./TipsText.vue";
import WyseBaseButton from "./WyseBaseButton.vue";

function floorPlanAndApartmentUnitParser(f) {
  return {
    ...f,
    rent_max: parseInt(f.rent_max),
    rent_min: parseInt(f.rent_min),
    sq_ft_max: parseInt(f.sq_ft_max),
    sq_ft_min: parseInt(f.sq_ft_min),
  };
}

export default {
  name: "ContactForm",
  components: {
    WyseBaseButton,
    WyseInput,
    // WyseLabel,
    // WyseSelect,
    WyseInputGroup,
    WyseTextarea,
    Spinner,
    TipsText,
  },
  inject: ["formHandler"],
  data() {
    return {
      loading: false,
      showMessage: false,

      selectedRoomType: "apartment", //floorPlan //apartment
      bedroomOptions: [
        //{ value: "0", text: "Studio" },
        //{ value: "1", text: "1 Bed" },
        //{ value: "I don't know", text: "I Don't Know" },
      ],

      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        desiredBedroom: "",
        desiredMoveInDate: "",
      },
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        message: false,
      },
    };
  },
  methods: {
    handleFormSubmit() {
      Object.keys(this.formData).forEach((k) => {
        if (k !== "message") this.errors[k] = !this.formData[k];
      });
      let isValid = Object.values(this.errors).every((v) => !v);
      console.log(this.formData);
      console.log({ isValid });
      if (isValid) {
        this.loading = true;
        this.formHandler
          .scheduleTour(
            this.formData.firstName,
            this.formData.lastName,
            this.formData.email,
            this.formData.phone,
            this.formData.message,
            this.formData.desiredMoveInDate,
            "",
            this.formData.desiredBedroom
          )
          .then((d) => {
            // TODO: Navigate to success page
            console.log(d);
          })
          .catch(() => {
            // TODO: Navigate to error page
          })
          .finally(() => {
            this.loading = false;
            // This is the success page
            router.push("/schedule-success");
          });
      }
    },
    checkAvailabilitySortFunction(a, b) {
      return a.rent_min - b.rent_min;
    },
    checkAvailabilityFilterFunction(a) {
      let bedroomsAvailable = this.availabilityData?.attributes.units
        .filter(
          (unit) =>
            unit.occupancy_status == "vacant" &&
            unit.leased_status == "available"
        )
        .map((unit) => unit.floor_plan_id);
      if (a.occupancy_status) {
        return (
          a.occupancy_status == "vacant" &&
          a.leased_status == "available" &&
          (a.sq_ft_min > 0 || a.rent_min >= 100)
        );
      }
      return (
        bedroomsAvailable.includes(a.floor_plan_id) &&
        (a.sq_ft_min > 0 || a.rent_min >= 100)
      );
    },
  },
  computed: {
    availabilityData() {
      return this.$store.state.availability.data;
    },
    floorPlans() {
      const attributes = this.availabilityData?.attributes;
      if (attributes?.floor_plans) {
        return attributes.floor_plans
          .map(floorPlanAndApartmentUnitParser)
          .sort(this.checkAvailabilitySortFunction)
          .filter(this.checkAvailabilityFilterFunction);
      }
      return [];
    },

    apartments() {
      const attributes = this.availabilityData?.attributes;
      if (attributes?.units) {
        return attributes.units
          .map(floorPlanAndApartmentUnitParser)
          .sort(this.checkAvailabilitySortFunction)
          .filter(this.checkAvailabilityFilterFunction);
      }
      return [];
    },
    allList() {
      if (this.selectedRoomType === "floorPlan") return this.floorPlans;
      else if (this.selectedRoomType === "apartment") return this.apartments;

      return [];

      //return this.availabilityData?.attributes.units;
    },
  },
  mounted() {
    let bedOptions = [];

    this.allList.forEach((f) => {
      bedOptions.push(parseInt(f?.bedrooms));
    });

    bedOptions = [...new Set(bedOptions)].sort();
    if (bedOptions.length > 0) {
      bedOptions.forEach((f) => {
        if (f == 0) {
          this.bedroomOptions.push({ text: "Studio", value: "0" });
        } else {
          f == 1
            ? this.bedroomOptions.push({ text: "1 Bedroom", value: "1" })
            : this.bedroomOptions.push({
                text: f.toString() + " Bedrooms",
                value: f.toString(),
              });
        }
      });
    }
    this.bedroomOptions.push({ value: "I don't know", text: "I Don't Know" });

    console.log(this.allList);
    //console.log(this.$store.state.availability.data);
  },
};
</script>

<style lang="scss" scoped>
.wyse-select-container {
  margin-top: 24px;
}

.wyse-base-button {
  margin-top: 20px;
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
}
</style>