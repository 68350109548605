import axios from "axios";

export default class FormHandler {
  constructor(kyzenApiId, dyverseApiId) {
    this.kyzenApiId = kyzenApiId;
    this.dyverseApiId = dyverseApiId;
  }

  applyNow(firstName, lastName, email) {
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    const url = `https://leads.dyverse.com/properties/${this.dyverseApiId}/form-submit`;
    return axios.post(url, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  }

  scheduleTour(
    firstName,
    lastName,
    email,
    phone,
    comments,
    tourDate,
    tourTime,
    desiredBedroom
  ) {
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("comments", comments);
    formData.append("tour_date", tourDate);
    formData.append("tour_time", tourTime);
    formData.append("desired_bedroom", desiredBedroom);
    const url = `https://leads.dyverse.com/properties/${this.dyverseApiId}/form-submit`;
    return axios.post(url, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  }

  getBasicAvailableRoomData() {
    return axios.get(
      `https://api.kyzen.io/v1/properties/${this.kyzenApiId}/basic_availability`
    );
  }

  getTourTimes() {
    return axios.get(
      `https://leads.dyverse.com/properties/${this.dyverseApiId}/get-tour-times`
    );
  }

  getAvailability() {
    return axios.get(
      `https://api.kyzen.io/v1/properties/${this.kyzenApiId}/availability`
    );
  }
}
