<template>
  <div id="app" :style="themeStyles">
    <slot></slot>
  </div>
</template>

<script>
import { rgba, lighten } from "polished";
import { getSettings } from "@/utils";
import FormHandler from "@/utils/FormHandler";

const settings = getSettings();
const formHandler = new FormHandler(settings.kyzenId, settings.dyverseId);

export default {
  name: "SettingsProvider",
  provide: { settings, formHandler },
  computed: {
    themeStyles() {
      const stylesObject = {};
      const themeSettings = settings.interface;
      Object.keys(themeSettings).map(
        (key) => (stylesObject[`--${key}`] = themeSettings[key])
      );
      stylesObject["--primaryColorOp20"] = rgba(
        themeSettings.primaryColor,
        0.2
      );
      stylesObject["--primaryColorOp25"] = rgba(
        themeSettings.primaryColor,
        0.2
      );
      stylesObject["--primaryColorOp35"] = rgba(
        themeSettings.primaryColor,
        0.35
      );
      stylesObject["--primaryColorOp65"] = rgba(
        themeSettings.primaryColor,
        0.65
      );
      stylesObject["--backgroundColorLighten25"] = lighten(
        0.25,
        themeSettings.backgroundColor
      );
      stylesObject["--backgroundColorLighten10"] = lighten(
        0.1,
        themeSettings.backgroundColor
      );
      stylesObject["--backgroundColorLighten5"] = lighten(
        0.05,
        themeSettings.backgroundColor
      );
      stylesObject["--iconColorLighten10"] = lighten(
        0.1,
        themeSettings.iconColor
      );
      return stylesObject;
    },
  },
  mounted() {
    this.$store.commit("setSettings", settings);
    this.$store.dispatch("fetchBedrooms", formHandler);
    this.$store.dispatch("fetchTourTimes", formHandler);
    this.$store.dispatch("fetchAvailability");
  },
};
</script>

<style lang="scss">
#app {
  min-width: 100vw;
  height: 100vh;
  color: var(--primaryColor);
  background: radial-gradient(
    50% 50% at 50% 50%,
    var(--backgroundColorLighten5) 46.7%,
    var(--backgroundColor) 100%
  );
  overflow-y: auto;
}
</style>
