<template>
  <div class="availability-card">
    <div @click="showModal = true">
      <div class="top-container">
        <div>
          <camera-icon />
          {{ title }}
        </div>
        <div @click.stop="handleClick()">
          INQUIRE
          <right-arrow-icon />
        </div>
      </div>
      <p class="subtitle">{{ subtitle }}</p>
      <p class="propery-name-date">
        <span>{{ propertyname }}</span> {{ availabledate }}
      </p>
    </div>

    <transition name="fade" appear>
      <div class="modal" v-if="showModal">
        <div class="close-header">
          <button class="button" @click.stop="showModal = false">
            <CloseZoom />
          </button>
        </div>

        <div class="container-zoom-wrapper">
          <PlaceholderImage/>

          <div class="container-zoom animate__animated animate__fadeInUp">
            <div class="top-container">
              <div>
                <camera-icon />
                {{ title }}
              </div>
              <div class="c-pointer" @click.stop="handleClick()">
                INQUIRE
                <right-arrow-icon />
              </div>
            </div>
            <p class="subtitle">{{ subtitle }}</p>
            <p class="propery-name-date">
              {{ propertyname }} {{ availabledate }}
            </p>
          </div>
        </div>

        <tips-text class="availibility-disclaimer animate__animated animate__fadeInUp">
          Prices may vary depending on your preferred lease term, lease signing
          date and other conditions. Square footage is approximate and may vary
          as well.
        </tips-text>
      </div>
    </transition>
  </div>
</template>

<script>
import RightArrowIcon from "./icons/RightArrowIcon.vue";
import CameraIcon from "./icons/CameraIcon.vue";
import CloseZoom from "@/components/icons/CloseZoom.vue";
import PlaceholderImage from "./icons/PlaceholderImage.vue";
import TipsText from "../components/TipsText.vue";

export default {
  name: "AvailabilityCard",

  components: { RightArrowIcon, CameraIcon, CloseZoom,PlaceholderImage, TipsText },

  props: {
    information: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },

  methods: {
    handleClick() {
      this.$store.commit("setAvailabilityBedroomCount", this.bedrooms);
      this.$router.push("/inquire-availability");
    },
    getFormattedDate(d) {
      let date = new Date(d),
        year = date.getFullYear(),
        month = (1 + date.getMonth()).toString(),
        day = date.getDate().toString();

      month = month.length > 1 ? month : "0" + month;
      day = day.length > 1 ? day : "0" + day;

      return month + "/" + day + "/" + year;
    },
  },

  computed: {
    title() {
      if (this.type === "floorPlan") return this.information?.floor_plan_id;
      else if (this.type === "apartment") return this.information?.unit_id;
      return "";
    },
    subtitle() {
      const { bedrooms, bathrooms, sq_ft_max, rent_min } = this.information;
      const bedText = bedrooms > 0 ? `${bedrooms} Bed` : `Studio`;
      return `${bedText}/${bathrooms} Bath | ${sq_ft_max.toLocaleString()} sq. ft. | From $${rent_min.toLocaleString()}`;
    },
    propertyname() {
      return this.information.property_title;
    },
    availabledate() {
      return this.type === "apartment"
        ? "| Available " +
            this.getFormattedDate(this.information.date_available)
        : "";
    },
    bedrooms() {
      return this.information.bedrooms;
    },
    buttonText() {
      if (this.type === "floorPlan") return "View Floor Plan";
      else if (this.type === "apartment") return "View Apartment";
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.availability-card {
  //border: 2px solid rgba(255, 255, 255, 0.35);
  border: 2px solid var(--primaryColorOp35);
  padding: 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  letter-spacing: 0em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--backgroundColorLighten10);
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }

  .subtitle {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
  }
  .propery-name-date {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    span {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    & > div {
      display: flex;
      align-items: center;

      &:first-child {
        svg {
          margin-right: 9px;
        }
        font-size: 16px;
        font-weight: 600;
      }

      &:last-child {
        svg {
          margin-left: 9px;
        }
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-align: right;
      }
    }
  }

  .container-zoom-wrapper {
    border: 2px solid var(--primaryColorOp35);
    border-radius: 10px;
  }
  .container-zoom {
    padding: 15px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 46px;
  width: 100%;
  background-color: var(--backgroundColor);
  padding: 30px 20px;
  z-index: 9999;
  cursor: default;

  div.close-header {
    height: 40px;
    background: var(--backgroundColor);
    margin-bottom: 15px;
    position: relative;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .availibility-disclaimer {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .c-pointer {
    cursor: pointer;
  }
}
</style>