<template>
  <layout title="Availability" logo="" class="check-availability">
    <template>
      <div
        class="availability-filter-container animate__animated animate__fadeIn animate__delay-50s"
      >
        <div class="availability-filter-wrapper">
          <div class="wyse-select-container">
            <wyse-select
              defaultText="All Bathrooms"
              :options="bedroomOptions"
              v-model="selectedBedrooms"
            />
            <wyse-select
              defaultText="All Bathrooms"
              :options="bathroomOptions"
              v-model="selectedBathrooms"
            />
          </div>

          <div class="range-wrapper">
            <wyse-range
              v-model="currentRentValue"
              :min="`${minRent}`"
              :max="`${maxRent}`"
            />
            <p class="max-rent">
              Max Rent ${{ parseInt(currentRentValue).toLocaleString() }}
            </p>
          </div>
        </div>

        <div class="availability-cards-wrapper">
          <availability-card
            v-for="(information, index) in selectedList"
            :key="index"
            :information="information"
            :type="selectedRoomType"
          />

          <div class="no-result" v-if="selectedList.length == 0">
            “Sorry! There are no apartments available using your search
            criteria.”
          </div>

          <tips-text class="availibility-disclaimer">
            Prices may vary depending on your preferred lease term, lease
            signing date and other conditions. Square footage is approximate and
            may vary as well.
          </tips-text>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <page-footer
        footerText="Powered by"
        @click.native="handleFooterClick()"
      />
    </template>
  </layout>
</template>

<script>
import PageFooter from "../components/layout/PageFooter.vue";
import Layout from "../components/layout/Layout.vue";
import WyseRange from "../components/form/WyseRange.vue";
import WyseSelect from "../components/form/WyseSelect.vue";
import AvailabilityCard from "../components/AvailabilityCard.vue";
import TipsText from "../components/TipsText.vue";

function floorPlanAndApartmentUnitParser(f) {
  return {
    ...f,
    rent_max: parseInt(f.rent_max),
    rent_min: parseInt(f.rent_min),
    sq_ft_max: parseInt(f.sq_ft_max),
    sq_ft_min: parseInt(f.sq_ft_min),
  };
}

export default {
  name: "CheckAvailability",
  components: {
    AvailabilityCard,
    Layout,
    PageFooter,
    WyseRange,
    WyseSelect,
    TipsText,
  },
  data() {
    return {
      selectedBedrooms: "all",
      selectedBathrooms: "all",
      selectedRoomType: "apartment",
      bedroomOptions: [
        { text: "All Bedrooms", value: "all" },
        //{ text: "Studio", value: "0" },
        //{ text: "1 Bed", value: "1" },
        //{ text: "2 Bed", value: "2" },
      ],
      bathroomOptions: [
        { text: "All Bathrooms", value: "all" },
        //{ text: "1 Bathrooms", value: "1" },
        //{ text: "2 Bathrooms", value: "2" },
      ],
      /*
      roomTypeOptions: [
        { text: "Apartments", value: "apartment" },
        { text: "Floor Plans", value: "floorPlan" },
      ],
      */
      currentRentValue: "0",
    };
  },
  methods: {
    handleFooterClick() {
      this.$router.go(-1);
    },
    checkAvailabilitySortFunction(a, b) {
      return a.rent_min - b.rent_min;
    },
    checkAvailabilityFilterFunction(a) {
      let bedroomsAvailable = this.availabilityData?.attributes.units
        .filter(
          (unit) =>
            unit.occupancy_status == "vacant" &&
            unit.leased_status == "available"
        )
        .map((unit) => unit.floor_plan_id);
      if (a.occupancy_status) {
        return (
          a.occupancy_status == "vacant" &&
          a.leased_status == "available" &&
          (a.sq_ft_min > 0 || a.rent_min >= 100)
        );
      }
      return (
        bedroomsAvailable.includes(a.floor_plan_id) &&
        (a.sq_ft_min > 0 || a.rent_min >= 100)
      );
    },
  },
  computed: {
    availabilityData() {
      return this.$store.state.availability.data;
    },

    floorPlans() {
      const attributes = this.availabilityData?.attributes;
      if (attributes?.floor_plans) {
        return attributes.floor_plans
          .map(floorPlanAndApartmentUnitParser)
          .sort(this.checkAvailabilitySortFunction)
          .filter(this.checkAvailabilityFilterFunction);
      }
      return [];
    },

    apartments() {
      const attributes = this.availabilityData?.attributes;
      if (attributes?.units) {
        return attributes.units
          .map(floorPlanAndApartmentUnitParser)
          .sort(this.checkAvailabilitySortFunction)
          .filter(this.checkAvailabilityFilterFunction);
      }
      return [];
    },

    allList() {
      if (this.selectedRoomType === "floorPlan") return this.floorPlans;
      else if (this.selectedRoomType === "apartment") return this.apartments;
      return [];
    },

    selectedList() {
      let result;
      if (this.selectedBedrooms === "all" && this.selectedBathrooms === "all") {
        result = this.allList;
      } else {
        /*
        result = this.allList.filter(
          (f) => f?.bedrooms === this.selectedBedrooms
        );
        */
        if (this.selectedBedrooms === "all") {
          result = this.allList.filter(
            (f) => f?.bathrooms === this.selectedBathrooms
          );
        } else if (this.selectedBathrooms === "all") {
          result = this.allList.filter(
            (f) => f?.bedrooms === this.selectedBedrooms
          );
        } else {
          result = this.allList.filter(
            (f) =>
              f?.bedrooms === this.selectedBedrooms &&
              f?.bathrooms === this.selectedBathrooms
          );
        }
      }
      return result.filter(
        (f) => f.rent_min <= parseInt(this.currentRentValue)
      );
    },

    minRent() {
      let min = 0,
        min_val_arr = [];

      this.allList.forEach(function(f) {
        min_val_arr.push(f.rent_min);
      });

      if (min_val_arr.length > 0) {
        min = Math.min(...min_val_arr);
        if (min < 100) {
          min = 100;
        }
      }
      return min;
    },

    maxRent() {
      let max = 0;
      this.allList.map((f) => {
        if (f?.rent_max > max) {
          max = f.rent_max;
        }
      });
      return max > 100 ? `${max}` : `100`;
    },
  },
  watch: {
    allList(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentRentValue = this.maxRent;
      }
    },
  },
  mounted() {
    let bedOptions = [],
        bathOptions = [];

    if (this.allList.length > 0) {
      //max value setting
      let max = 0;
      this.allList.map((f) => {
        if (f?.rent_max > max) {
          max = f.rent_max;
        }
      });
      max = max > 100 ? `${max}` : `100`;
      this.currentRentValue = max;

      // Bedrooms & Bathrooms options update
      this.allList.forEach((f) => {
        bedOptions.push(parseInt(f?.bedrooms));
        bathOptions.push(parseInt(f?.bathrooms));
      });

      bedOptions = [...new Set(bedOptions)].sort();
      if(bedOptions.length>0){
        bedOptions.forEach((f) => {
            if(f == 0){ 
              this.bedroomOptions.push({ text: "Studio", value: "0" })
            } else{
              (f == 1) ? this.bedroomOptions.push({ text: "1 Bedroom", value: "1" }) : this.bedroomOptions.push({ text: f.toString() + " Bedrooms", value: f.toString() });
            }
        });
      }

      bathOptions = [...new Set(bathOptions)].sort();
      if(bathOptions.length>0){
        bathOptions.forEach((f) => {
          (f == 1) ? this.bathroomOptions.push({ text: "1 Bathroom", value: "1" }) : this.bathroomOptions.push({ text: f.toString() + " Bathrooms", value: f.toString() });
        });
      }

    }
  },
};
</script>

<style lang="scss">
.availability-filter-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.availability-filter-wrapper {
  flex-shrink: 0;
}
.availability-cards-wrapper {
  flex: 1 1 0%;
  overflow-y: auto;
}

.check-availability {
  .header {
    margin-bottom: 17px;
    min-height: 35px !important;
  }
}

.wyse-select-container {
  display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
  }
}

.range-wrapper {
  position: relative;
  .max-rent {
    text-align: center;
    margin-top: 6px;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0em;
    margin-bottom: 15px;
    position: absolute;
    top: 0;
    //width: 200px;
    text-align: center;
    left: 50%;
    //margin-left: -100px;
    margin-top: -7px;
    background-color: var(--backgroundColor);
    padding: 0 8px;
    transform: translate(-50%, 0);
    border-radius: 5px;
  }
  margin-bottom: 20px;
}

.availibility-disclaimer {
  margin-right: 10px;
  margin-bottom: 10px;
}

.wyse-option {
  font-size: 17px;
  font-weight: 400 !important;
}
.no-result {
  border: 2px solid var(--primaryColorOp35);
  padding: 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  letter-spacing: 0em;
  color: var(--primaryColorOp65);
}
</style>