<template>
  <button type="button" :class="{ 'wy-selected': selected }">
    <div class="is-selected" v-if="selected">
      <checkmark-icon />
    </div>
    <div class="button-title">
      {{ title }}
    </div>
    <div class="extra-infos">
      <div class="area-text">{{ area }}</div>
      <div class="rent-text">{{ rent }}</div>
    </div>
  </button>
</template>

<script>
import CheckmarkIcon from "./icons/CheckmarkIcon.vue";

export default {
  name: "BedroomSelectButton",
  components: { 
    CheckmarkIcon 
  },

  props: {
    bedroomInfo: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title() {
      switch (this.bedroomInfo.label) {
        case "One Bedroom":
          return "1 Bed";
        case "Two Bedroom":
          return "2 Bed";
        default:
          return this.bedroomInfo.label;
      }
    },
    area() {
      const { sq_ft_max, sq_ft_min } = this.bedroomInfo;
      if (sq_ft_max === sq_ft_min) {
        return `${sq_ft_max.toLocaleString()} sq. ft.`;
      }
      return `${sq_ft_min.toLocaleString()} - ${sq_ft_max.toLocaleString()} sq. ft.`;
    },
    rent() {
      const { rent_min } = this.bedroomInfo;
      return `From $${rent_min.toLocaleString()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  height: 59px;
  border-radius: 50px;
  border: 3px solid var(--primaryColor);
  background: transparent;
  width: 100%;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 37px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--primaryColorOp20);
  }
  &.wy-selected{
    border-color: var(--secondaryColor);
  }

  &:focus-visible {
    outline: none;
  }

  .is-selected {
    margin-right: 10px;
  }

  .button-title {
    font-size: 19px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--primaryColor);
  }

  .extra-infos {
    margin-left: auto;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--primaryColorOp65);
  }
}
</style>
