<template>
    <span class="close-zoom">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3076 27.5471C25.5899 27.8304 25.9709 27.99 26.3685 27.9917C26.7862 28.0358 27.2033 27.9026 27.5202 27.6238C27.5445 27.6007 27.5681 27.577 27.5908 27.5524C28.1629 26.9342 28.1313 25.964 27.5202 25.3853L22.1395 19.9738L27.5202 14.5305C27.9969 13.9357 27.9668 13.0763 27.4496 12.5172C26.8777 11.899 25.9187 11.8668 25.3075 12.4453L19.9963 17.8184L14.5776 12.3687C14.0103 11.8771 13.1734 11.8771 12.606 12.3687C11.9703 12.9194 11.8963 13.8873 12.4407 14.5304L17.8436 19.9962L12.4407 25.462C11.8531 26.06 11.8531 27.0257 12.4407 27.6237C12.7387 27.8828 13.1244 28.0148 13.5167 27.9917C13.9039 28.0108 14.2835 27.8791 14.5776 27.6237L19.9804 22.158L25.3076 27.5471Z" fill="currentColor"/>
            <circle cx="20" cy="20" r="19" stroke="currentColor" stroke-opacity="0.25" stroke-width="2"/>
        </svg>
    </span>
</template>

<script>
export default ({
    methods: {
        /*
        goForward(){
            return this.$router.go(1);
        }
        */
    }
})
</script>
<style scoped>
    .close-zoom{
        position: absolute;
        top: 0;
        right: 10px;
        opacity: 0.75;
        transition: all .5s;
        cursor: pointer;
        z-index: 9999;
    }

    .close-zoom:hover{
        opacity: 1;
    }
    svg {
        color: var(--primaryColor);
    }

</style>